import { render, staticRenderFns } from "./analyseDetail.vue?vue&type=template&id=f8c0d972&scoped=true&"
import script from "./analyseDetail.vue?vue&type=script&lang=js&"
export * from "./analyseDetail.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f8c0d972",
  null
  
)

export default component.exports
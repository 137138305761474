<template>
  <pdf-view  v-if="patent.fileUrl" :url = "patent.fileUrl"/>
   
</template>

<script>

import pdfView from "@/components/all/pdfView"; //引入尾部组件

export default {
  name: 'AnalyseDetail',
  components: {
    pdfView,
  },

  data() {
    return {   
      patent: {},
      id: '',
      title: '',
      primary: 1, //一级菜单的选中样式
      istrue: true, //刷新页面
      crumblist: [
        {
          name: "首页",
          path: "/home",
          query: {},
        },
        {
          name: "产业分析报告",
          path: "/analyse",
          query: {},
        },
        {
          name: "详细信息",
          path: "",
          query: {},
        },
      ],
    }
  },
  
  created() {
    this.init()
    this.getdata();
  },
  methods: {
    init(){
      this.id = this.$route.params.id
      console.log("   patent id " ,   this.id)
    },
    getdata() {
      console.log("=====   patent.fileUrl " + this.patent.fileUrl)
      this.$api.getAnalyseDetail(this.id).then((res) => {
        this.patent = res.data;

        console.log("----   patent.fileUrl " + this.patent.fileUrl)
        this.title = this.patent.title
        console.log("  get patent " , this.patent)
      });
    },
  },
};
</script>

<style scoped lang="less">


</style>
